import React from "react";

const HowItWorks = () => {
  return (
    <section className="bg-accent-200 relative py-12">
      <h2 className="text-2xl md:text-3xl font-semibold text-accent-600 text-shadow text-center block mb-4 font-display">How Our Process Works</h2>
      <div className="container lg:max-w-6xl flex flex-wrap py-3 mx-auto px-6 md:px-8">
        <div className="w-full flex items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-6 text-center w-full">


            
            <div className="flex items-center md:items-start py-4 md:py-0">
            <div class="bg-accent-600 flex flex-shrink-0 font-semibold h-10 items-center justify-center md:h-12 md:w-12 rounded-full text-white w-10">1</div>

              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5 mb-1">
               Tell Us About Your Property

                </h3>
                <p className="text-gray-600 text-sm leading-6">
                This is quick, easy, and free! We’ll review the details of your home and schedule an appointment to meet with you.
                </p>
              </div>
            </div>

            <div className="flex items-center md:items-start py-4 md:py-0">
            <div class="bg-accent-600 flex flex-shrink-0 font-semibold h-10 items-center justify-center md:h-12 md:w-12 rounded-full text-white w-10">2</div>
              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5 mb-1">
                Meet With a Member of Our Team

                </h3>
                <p className="text-gray-600 text-sm leading-6">
                We’ll walk the property with you. We only need to view your property once before we can make you an offer!


                </p>
              </div>
            </div>
            <div className="flex items-center md:items-start py-4 md:py-0">
            <div class="bg-accent-600 flex flex-shrink-0 font-semibold h-10 items-center justify-center md:h-12 md:w-12 rounded-full text-white w-10">3</div>
              <div className="text-left pl-3">
                <h3 className="font-display font-semibold text-gray-800 leading-5 mb-1">
                Choose Your Closing Date
                </h3>
                <p className="text-gray-600 text-sm leading-6">
                We can close on your schedule, whether you need a fast or slow closing. We are entirely flexible toward what you prefer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
