import React, { useState } from 'react'
import { navigate } from "gatsby"
import { setItemToLStore } from '../helpers/hash'
import InputMask from "react-input-mask";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function BookDownloadForm({ hideLabels, showPlaceholders, stacked, fontsize, footerContained, bookName }) {
  const [formInputStates, setFormInputStates] = useState()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [formSubmitErrors, setFormSubmitErrors] = useState([])


  function handleChange(e) {
    setFormInputStates({ ...formInputStates, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    setSubmitInProgress(true)
    e.preventDefault()

    if (!validateForm()) {
      window.scroll(0, 0)
      setSubmitInProgress(false)
      return
    }
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'subject': "Book download " + (formInputStates.Lastname + ", " + formInputStates.Firstname + " (" + (new Date().toLocaleString()) + ")"),
        'form-name': form.getAttribute('name'),
        ...formInputStates,
        'landingURL': (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('lNdUrL') ? window.localStorage.getItem('lNdUrL') : 'Not Provided'),
        'currentPath': (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('crNtPaTH') ? window.localStorage.getItem('crNtPaTH') : 'Not Provided')

      }),
    })
      .then(() => {
        setItemToLStore('form', { date: new Date(), ...formInputStates })
        //after submit success, show spinner .05 sec 
        setTimeout(
          () => navigate(form.getAttribute('action')),
          2000
        );

      })
      .catch((error) => {
        alert(error)
        setSubmitInProgress(false)
      })
  }


  function validateForm() {
    let formValidateSuccess = true
    let formErrorEmail = false

    let listOfErrors = []



    if (
      !formInputStates.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      listOfErrors.push("Email is a required field.")
      formValidateSuccess = false
      formErrorEmail = true
    }
    setFormSubmitErrors(listOfErrors)

    return formValidateSuccess
  }


  return (


    <form
      name="bookdownload"
      method="post"
      action="/report-download/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >



      {formSubmitErrors.length > 0 ?
        <div className="rounded-xl mb-6 bg-white p-4">
          <span class="block text-center mb-2 text-red-600 font-semibold">
            <svg className="w-6 h-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg> Oops! There were some problems with your submission.
        </span>

          <ul className="p-0 m-0 list-none text-center">

            {formSubmitErrors.map((error) => (<li className="block">- {error}</li>))}
          </ul>

        </div> : null}


      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="landingURL" />
      <input type="hidden" name="subject" />
      <input type="hidden" name="currentPath" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-6">



        <div className={`${stacked ? 'col-span-6' : 'col-span-3'}`}>
          <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'Firstname'}>First Name</label>
          <input
            className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            type={'text'}
            name={'Firstname'}
            onChange={handleChange}
            id={'Firstname'}
            required={true}
            placeholder={showPlaceholders ? 'First Name' : ''}
          />
        </div>


        <div className={`${stacked ? 'col-span-6' : 'col-span-3'}`}>
          <label className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'email'}>Email</label>

          <input
            className="w-full input border-gray-200 p-3 bg-white rounded-xl border appearance-none"
            type={'email'}
            name={'email'}
            onChange={handleChange}
            id={'email'}
            required={true}
            placeholder={showPlaceholders ? 'Email' : ''}
          />

        </div>

      </div>
      <div className="field">


        <button className="mt-4 button-active-bhvr w-full md:text-lg cursor-pointer transition duration-150 ease-in-out bg-gradient-to-r from-accent-500 to-accent-700   focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-full text-white font-display flex items-center justify-center" type="submit">Download Now {submitInProgress ? <svg class="animate-spin ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> : null} </button>
      </div>

      <div className={`mt-3`}>

        <p className="text-gray-500 text-center text-sm flex items-center justify-center"> <svg className="w-4 h-4 mr-1 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg> Your privacy is important to us.</p>
      </div>

    </form>

  )
}

export default BookDownloadForm
