import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import BookDownloadForm from '../../components/BookDownloadForm'
import Img from "gatsby-image";
import HowItWorks from "../../components/HowItWorks";

const SellingToAProfessional = ({data}) => {
  
  const { bookImage, teamImage } = data;

 
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Learn The Pros and Cons Of Selling Your House To A Professional House Buyer | Sell Your Home For Cash Sacramento'}
        description={'FREE Guide: Learn The Pros and Cons Of Selling Your House To Local Sacramento Professional Home Buyer And What 4 Hidden Costs Creep Up When Listing With An Agent Or Selling It Yourself PLUS: What&#8230;'}
        keywords={''}
      />


<section className="py-16 section-stripe-bg bg-gray-50 ">
   <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
      <div className="w-full md:w-2/3 md:pr-4 prose">
        

       
         
         
             <h1><strong>FREE Guide:</strong> Learn The <strong>Pros and Cons Of Selling Your House</strong> To Local Sacramento Professional Home Buyer</h1>
<h2>    <figure class="float-right"> <Img
              className="rounded-lg shadow-lg object-cover object-center ml-8" 
              fixed={bookImage.childImageSharp.fixed}
              width={80}
              alt="Sell Your Home For Cash Sacramento is a professional home buyer in Sacramento, CA."
            /> </figure>
             And What <span className="text-red-700">4 Hidden Costs Creep Up When Listing With An Agent</span> Or Selling It Yourself</h2>
<p><strong>PLUS: What big pitfalls to watch out for when you’re selling your house with a real estate agent, by yourself, and even to professional home buyer like Sell Your Home For Cash Sacramento </strong></p>
<p>Missing one of these critical “pitfalls” could mean more stress and expense for you when your house finally sells.</p>
<p><strong>There are 3 main ways to sell your CA house. &nbsp;</strong>All with their own pros and cons.</p>
<ol>
<li>List it with an&nbsp;<strong>agent</strong></li>
<li>Sell it&nbsp;<strong>yourself</strong>&nbsp;(FSBO)</li>
<li>Sell it to a&nbsp;<strong>professional home buyer</strong> in CA</li>
</ol>
<p>All three options are great in certain situations… and&nbsp;terrible in others.</p>
<p>Learn when to list with an agent, when to sell yourself, and when selling to a real estate investor makes the most sense.</p>
<p>And, learn the costs associated with each option… because you’ll be surprised at what “hidden costs” there are in listing with an agent or selling it yourself that most people never even think about until it’s too late.</p>
<p><strong>Enter in your name and email in the form to the right to download this free guide.</strong></p>
<p>If you need to sell your house quickly… this guide walks you through why real estate agents may end up costing you tens of thousands and still end up not getting your house sold.</p>
<p><strong>Download the guide today &gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;&gt;</strong></p>
<p>– Sell Your Home For Cash Sacramento</p>
      </div>
      <div className="w-full md:w-1/3 md:pl-4">
         <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
            <div className="px-8 pt-8">
               <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Download Your FREE "Pros, Cons, and Pitfalls" Guide Below</h2>
               <p class="text-gray-600 mb-2 leading-6 mt-1">Just put in your name and email, click "Submit" and we'll email you the Free Guide right away.</p>
            </div>
            <div className="p-8 pt-4">
               <BookDownloadForm stacked={true} hideLabels={true} showPlaceholders={true} />
               <span className="block text-xs text-gray-600 text-center mt-2">We never rent, sell, or share your info with anyone. It's not cool and we hate spam</span>
            </div>
         </div>
      </div>
   </div>
</section>



      <HowItWorks />

    </Layout>
  );
};


export default SellingToAProfessional;



export const pageQuery = graphql`
  query SellingToAPro {

    bookImage: file(relativePath: { eq: "selling-to-a-professional.jpeg" }) {
      childImageSharp {
        fixed(width: 230) {
          ...GatsbyImageSharpFixed
          src
        }
      }
    }
    teamImage: file(relativePath: { eq: "team-pic.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          src
        }
      }
    }
  }
`;
